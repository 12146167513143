@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.App{
  height: 100vh;
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.MainSetting{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 98%;
  gap: 0.5rem;
  margin: 0.5rem;
}

.SideBar{
    width: 15%;
    border-right: 5px solid rgb(240, 240, 240);
    height: 100%;
    padding: 0.5rem;
    margin-top: 3rem;
    overflow: auto;
}

.Content{
  width: 85%;
  height: 100%;
  overflow: auto;
}

.Heading{
  height: 5vh;
  display: flex;
  align-items: center;
  padding: 2rem;
  font-size: 1.5em;
  font-weight: bold;

}

.SubHeading{
  min-width: 20%;
  height: 2vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  margin: 2rem;
  font-size: 1em;
  font-weight: bold;
  background: #000;
  border-radius: 15px;
  color: white;
}

.FormWraper{
  display: flex;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
}

.forms{
  width: 95%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 1rem;
  margin-bottom: 1rem;

}

.InputBox{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5rem;
  width: 100%;

  label{
      display: block;
      font-weight:600;
      margin: 0.5rem;
  }
  input,textarea,select{
      width: 95%;
      height: 3rem;
      border-radius: 3px;
      margin-top: 0.5rem;
      padding-left: 0.6rem;
      font-size:1em;
      border: 2px solid rgb(190, 185, 185);
  }

  .errorInput{
      width: 95%;
      height: 3rem;
      border-radius: 3px;
      margin-top: 0.5rem;
      padding-left: 0.6rem;
      font-size:1em;
      border: 1px solid #FF3333;
  }
}


.Button{
        margin: 2rem 0 0 0.5rem;

        button{
          padding: 0.5rem 2rem;
          background-color: rgb(41, 41, 247);
          border: none;
          color: white;
          font-size: 1em;
          border-radius: 5px;
        }
}

.loaderContainer{
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  width: 100px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-right-color: #2909e2;
  animation: l15 1s infinite linear;
}
.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
  animation: l15 2s infinite;
}
.loader::after {
  margin: 8px;
  animation-duration: 3s;
}
@keyframes l15{
  100%{transform: rotate(1turn)}
}