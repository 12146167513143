.HomeSpliter{
    display: flex;
    justify-content: center;
    gap: 2rem;
    width: 100%;
    min-height: 100vh;
    margin-top: 2rem;
}

.VisitList{
    width: 40%;
    border-left: 5px solid rgb(240, 240, 240);
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    overflow: auto;
    table{
        width: 100%;
        border-collapse: collapse;
        border: 1px solid black;
        th, td{
            border: 1px solid black;
            padding: 0.5rem;
        }
    }
}

.HomeContainer{
    width: 95%;
    height: 100%;
    padding: 0.5rem;
}

.HomeHeader{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

}

.SearchContainer{
    display: flex;
    justify-content: center;
    width: 100%;
}

.HomeContainer .SearchBy{
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
    font-size: 1em;

}


.SearchSide{
    width: 100%;
    padding: 1rem;
}


.SearchResult{
    width: 100%;
}