/* Cards.css */
  .outter_container {
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 3rem 0rem 5rem 0;
  }

  .card_container {
    background-color: #000000;
    color: white;
    min-width: 330px;
    border-radius: 15px;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    transition: 0.4s;
    position: relative;
    box-shadow: rgba(85, 80, 110, 0.11) 0 0 0px 2px;
  }

  .info_container {
    display: flex;
    flex-direction: column;
  }

  .info_container p {
    margin: 8px;
  }

  .description {
    color: #818181;
    font-size: 17px;
    font-weight: 400;
  }

  .title {
    transform: translateY(0px);
    font-size: 19px;
    font-weight: 600;
  }

  .actions_cnt {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
  }

  .actions {
    display: none;
    position: absolute;
    flex-direction: column;
    gap: 5px;
    background-color: white;
    padding: 9px;
    border-radius: 7px;
    box-shadow: 0 0 8px 2px rgba(17, 12, 46, 0.11);
    top: 25px;
    font-weight: medium;
    left: 18px;
  }

  .actions span {
    cursor: pointer;
  }

  .actions span:hover {
    scale: 1.019;
    transition: 0.3s;
  }

  .menu {
    transform: translateX(10px);
    transition: 0.4s;
  }

  .menu:hover {
    cursor: pointer;
    scale: 1.088;
  }

  .date {
    font-weight: 500;
    transform: translateY(10px);
    background: white;
    padding: 5px;
    color: #000000;
    border-radius: 50px;
  }

  .left_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 100%;
    border-radius: 15px 0 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .view_task_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 100%;
    cursor: pointer;
  }

  .all_info {
    position: absolute;
    display: none;
    background-color: white;
    box-shadow: rgba(85, 80, 110, 0.11) 0 0 0px 2px;
    padding: 0.7em;
    border-radius: 10px;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 85%;
    left: calc(100% - 305px);
    bottom: 0;
    z-index: 1;
    font-size: 15px;
  }

  .title_all {
    margin: auto;
    text-align: center;
  }

  .info_value {
    font-weight: 600;
  }

  /* Status Colors */
  .ENCOURS {
    background-color: hsl(42, 96%, 47%);
  }

  .CLOTURER {
    background-color: hsl(150, 90%, 35%);
  }

  .ANNULE {
    background-color: hsl(357, 85%, 45%);
  }

  .ENCOURS_text {
    color: hsl(42, 96%, 47%);
  }

  .CLOTURER_text {
    color: hsl(150, 90%, 35%);
  }

  .ANNULE_text {
    color: hsl(357, 85%, 45%);
  }

  /* Hover Effects */
  .card_container:hover {
    transform: scale(0.98);
  }

  .left_overlay:hover + .all_info {
    display: block;
  }
