.Header{
    height: 10vh;
    margin: 1rem;
    display: flex;
    align-items: center;
    padding: 0.5rem 2rem;
    gap: 1rem;
    border-bottom: 2px solid gray;
}

.leftHeader, .rightHeader{
    display: flex;
    align-items: center;
    width: 100%;
    padding: auto;
}
.rightHeader{
    display: flex;
    justify-content: flex-end;
    align-items: end;
    gap: 1rem;
    cursor: pointer;
}
