.SideContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    overflow: auto;
}


nav ul{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    cursor: pointer;

    .Clicked{
        background-color: rgb(41, 41, 247);
        border: none;
        color: white;
        border-radius: 5px;
    }


    li{
        width: 100%;
        padding: 0.5rem;
        display: flex;
        gap: 0.5rem;
        vertical-align: middle;
        transition: background-color 0.5s, padding 0.5s;

        &&:hover{

            padding-left:0.5rem ;
            border: none;
            border-radius: 5px;
            background-color:rgb(190, 188, 188) ;
        }

    }

}