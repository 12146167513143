/* General container styles */
.tabs-container {
    width: 80%;
    margin: auto;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-bottom: 5rem;
  }

  /* Tab links styles */
  .tabs {
    display: flex;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
  }

  .tab-link {
    flex: 1;
    padding: 12px 20px;
    text-align: center;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s;
    color: #333;
  }

  .tab-link:hover {
    background-color: #e0e0e0;
  }

  .tab-link.active {
    background-color: #007bff;
    color: #fff;
    font-weight: bold;
  }

  /* Tab content styles */
  .tabs-content {
    padding: 20px;
    background-color: #fff;
  }

  .tab-pane {
    display: none;
    animation: fadeEffect 0.3s;
  }

  .tab-pane.active {
    display: block;
  }

  /* Fade-in effect */
  @keyframes fadeEffect {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
