@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-size: 1rem; /* 16px */
    line-height: 1.5; /* Improve readability */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*{
  scrollbar-width: thin;
  scrollbar-color:gray;

}


&::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

&::-webkit-scrollbar-thumb {
  background-color: gray;
}

&::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}




